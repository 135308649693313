import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { collection, addDoc,updateDoc ,query, where, getDocs, doc, getDoc} from "firebase/firestore"; 
import {db} from "../utils/firebase.js";

import redLogo from "../assets/logo-red.png";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  font-family: Cygnito Mono;
`;
const View = styled.div`
  height: 600px;
  max-width: 100%;
  margin: 25px;
  border-style: solid;
  border-color: #e63731;
  text-align: center;
`;
const Nav = styled.nav`
  padding-top: 30px;
  width: 100%;
  max-width: 1281px;
  margin: auto;
`;
const NavItems = styled.span`
  color: #e63731;
`;
const Logo = styled.img`
  width: 100px;
  max-width: 100%;
`;

const TerminalContent = styled.div`
  background-color: black;
  text-align:center;
  color: #e63731;
  padding-left: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const ButtonSection = styled.div`
  flex: 1;
`;

const Button = styled.button`
  background-color: #e63731;
  font-family: Cygnito Mono;
  font-size: 18px;
  border: none;
  padding: 5px 15px 5px 15px;
  margin-left: 5px;
  cursor: pointer;
`;
const TerminalInput = styled.input`
  background-color: black;
  color: #e63731;
  font-family: Cygnito Mono;
  border: none;
`;
const ThankYou = styled.h2`
	margin-top:150px;
	font-size:36px;
	line-height:1;
`;
const OrderPrice = styled.h3`
	margin-top:150px;
	font-size:26px;
	margin-bottom:70px;
`;
const UserLink = styled.a`
  color: #e63731;
  text-decoration:none;
`;


function SubmitOrder() {
	const [userName, setUserName] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [totalPrice, setTotalPrice] = useState("");
	const [films, setFilms] = useState([]);
	const navigate = useNavigate();
	const [preferences, setPreferences] = useState({});
	const [studentDiscount, setStudentDiscount]= useState();
	const [expressService, setExpressService]= useState();

	const { userid } = useParams();

useEffect(()=>{
	setFilms(JSON.parse(localStorage.getItem("films")));
	setPreferences(JSON.parse(localStorage.getItem("preferences")));
	setPreferences(JSON.parse(localStorage.getItem("preferences")));
	setStudentDiscount(JSON.parse(localStorage.getItem("studentDiscount")));
	setExpressService(JSON.parse(localStorage.getItem("expressService")));
},[]);
useEffect(()=>{
	const fetchUserName = async () => {
   const docRef = doc(db, "users", userid);
	const docSnap = await getDoc(docRef);

	if (docSnap.exists()) {
  		setUserName(docSnap.data().name);
  		setEmailAddress(docSnap.data().email);
	} else {
 		// doc.data() will be undefined in this case
 		console.log("No such document!");
	}
  }
	fetchUserName();
	},[userid]);

useEffect(()=>{
	let price = 0;
	for (const film of films){
	if(film.serviceType == "develop-only"){
            price +=300;
    }      
    if(film.serviceType == "scan-only"){        
	if(film.size.toLowerCase()  ==="medium"){
		if(film.format === "35mm-24"){
			price+=225;
		}
		else{
			price+=300;
		}
	}
	else if (film.size.toLowerCase()  ==="large"){
		if(film.format === "35mm-24"){
			price+=300;
		}
		else{
			price+=375;
		}

	}
	else if (film.size.toLowerCase()==="x-large"){
		if(film.format === "35mm-24"){
			price+=375;
		}
		else{
			price+=450;
		}
	}
    }
	if(film.serviceType == "scan-develop"){
		if(film.size.toLowerCase()  ==="medium"){
			if(film.format === "35mm-24"){
				price+=300;
			}
			else{
				price+=375;
			}
		}
		else if (film.size.toLowerCase()  ==="large"){
			if(film.format === "35mm-24"){
				price+=375;
			}
			else{
				price+=450;
			}
	
		}
		else if (film.size.toLowerCase()==="x-large"){
			if(film.format === "35mm-24"){
				price+=450;
			}
			else{
				price+=525;
			}
		}
	}
	//extra options
	if(film.serviceType == "develop-only" || film.serviceType == "scan-develop"){
		if(film.push=== "-3" || film.push=== "+3"){
			price+=225;
		}
		else if (film.push=== "-2" || film.push=== "+2"){
			price+=150;
		}
		else if (film.push=== "-1" || film.push=== "+1"){
			price+=75;
		}
	}
	if(film.serviceType =="scan-only" || film.serviceType == "scan-develop"){
		if(film.tiff.toLowerCase() === "tiff"){
			price+=25;
		}
		else if (film.tiff.toLowerCase()  === "dng"){
			price+=75;
		}
	}
}
	//if student discount 20% off final price
	if (expressService =="express"){
		price*=2;
	}
	else if (expressService == "semi-express"){
		price*=1.5;
	}
	if(studentDiscount){
		price = price*0.8;
	}
	setTotalPrice(price);
},[films]);

 const sendEmail= async(filmIDs)=>{ 
   let links =[];
   let htmlStart = "<h1>The Laboratory</h1>Dear friend in film, <br/><br/>Thank you for choosing THE LABORATORY as your film lab of choice.<br/><br/>The expected date of your film scans is detailed in the below link.";
   let htmlEnd = `<br/><br/><br/>Please note that we are closed Fridays and Saturdays. Should your due date fall on a weekend or public holiday, kindly expect your scans on the next working day.<br/><br/>
We are always doing our best to deliver your films to you as quickly as possible! Therefore, please resist the temptation to contact us about the status of your order unless you haven't received your scans by the expected date.
<br/><br/>Thanks again for choosing THE LABORATORY. <br/><br/>Meanwhile, keep shooting analog!<br/>********<br/><br/>Warm regards,<br/><br/>THE LABORATORY Team.`;
   filmIDs.map(filmID=>{
      links.push(`https://www.thelaboratory.film/film-status/${filmID}`)
   });
   links.map((link, index)=>{
    htmlStart+= `<br/><a href="${link}">Roll-${index+1}</a>`;
   });
   if(filmIDs.length > 0){
   	  const docRef = await addDoc(collection(db, "mail"), {
        to: emailAddress,
        message: {
        subject: "Your Film Order",
        html: htmlStart+htmlEnd
        },
        createdAt: new Date()
     });
   }  
}


async function uploadOrder(){
	function addDaysToDate(date, days){
		var res = new Date(date);
		res.setDate(res.getDate() + days);
		return res;
	}
	let filmIDs = [];
	for (const film of films){
		let docRef;
		try{
		if(expressService =="express"){
			 docRef = await addDoc(collection(db, "films"), {	
				...film, userName, email: emailAddress,userId:userid, status:"received", createdAt: new Date(), dueDate:addDaysToDate(new Date(), 1),expressService:1
			});
		}
		else if (expressService == "semi-express"){
			 docRef = await addDoc(collection(db, "films"), {	
				...film, userName, email: emailAddress,userId:userid, status:"received", createdAt: new Date(), dueDate:addDaysToDate(new Date(), 2),expressService:2
			});
		}
		else if (expressService == "7-9"){
			 docRef = await addDoc(collection(db, "films"), {	
				...film, userName, email: emailAddress,userId:userid, status:"received", createdAt: new Date(), dueDate:addDaysToDate(new Date(), 8),expressService:9
			});
		}
		else if (expressService == "10-12"){
			 docRef = await addDoc(collection(db, "films"), {	
				...film, userName, email: emailAddress,userId:userid, status:"received", createdAt: new Date(), dueDate:addDaysToDate(new Date(), 11),expressService:12
			});
		}
		else if (expressService == "13-16"){
			 docRef = await addDoc(collection(db, "films"), {	
				...film, userName, email: emailAddress,userId:userid, status:"received", createdAt: new Date(), dueDate:addDaysToDate(new Date(), 14),expressService:16
			});
		}
	
     filmIDs.push(docRef.id);
		}
		 catch(e){
		 	console.error("Error adding document: ", e);
		 }
		 finally{
		 		localStorage.setItem("film-ids", JSON.stringify(filmIDs));
		 		localStorage.removeItem("films");
		 }
	}
	if(preferences){
		try{
		 const docRef = doc(db, 'users', userid);
		 await updateDoc(docRef, {
 			 preferences
		});
		}
		 catch(e){
		 	console.error("Error updating document: ", e);
		 }
		 finally{
		 		localStorage.removeItem("preferences");
		 }
	}
		 sendEmail(filmIDs);
 	   navigate(`/admin/email/${userid}`);
}


  return (
    <Container>
      <Nav>
      <Link to="/admin">
          <Logo src={redLogo} />
      </Link>
        <NavItems>system name: ip address:</NavItems>
      </Nav>
      <View>
        <TerminalContent>
        <ThankYou>Thank you {userName}<br/>For your order </ThankYou>
        {studentDiscount?<h2><OrderPrice>The total for the order with the student discount is {totalPrice} EGP</OrderPrice></h2>:<OrderPrice>The total for the order is {totalPrice} EGP</OrderPrice>}
   		<Button onClick={uploadOrder}>Submit</Button>
        </TerminalContent>
      </View>
    </Container>
  );
}

export default SubmitOrder;
